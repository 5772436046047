import React from 'react'
import { Link } from 'gatsby'

function PostLink({ post }) {
  return (
    <section>
      <header>
        <h3>
          <Link to={post.frontmatter.slug}>{post.frontmatter.title}</Link>
        </h3>
        <p>{post.frontmatter.date}</p>
      </header>
      <p>{post.frontmatter.description}</p>
      <p>
        <Link to={post.frontmatter.slug}>Read more ...</Link>
      </p>
    </section>
  )
}

export default PostLink
