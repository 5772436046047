import React from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import BlogPostLink from '../components/BlogPostLink'
import Layout from '../components/layout'

function BlogPage({
  data: {
    allMarkdownRemark: { edges },
  },
}) {
  let Posts = edges
    .filter(
      (edge) =>
        !!edge.node.frontmatter.date && edge.node.frontmatter.draft === 'false'
    )
    .map((edge) => <BlogPostLink key={edge.node.id} post={edge.node} />)

  if (Posts.length === 0) {
    Posts = 'No posts yet! Check back soon!'
  }

  return (
    <Layout location="/blog">
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title="Carb Blog"
        meta={[
          {
            name: 'description',
            content:
              'Blog for Carb, an iOS app for people with diabetes to help find nutrition information',
          },
          {
            name: 'keywords',
            content: 'carb, blog, nutrition, diabetics, diabetes, reference',
          },
        ]}
      />
      <div id="main" className="wrapper style1">
        <div className="container">
          <header className="major">
            <h2>Carb Blog</h2>
          </header>
          {Posts}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
            title
            description
            draft
          }
        }
      }
    }
  }
`

export default BlogPage
